const experienceArr = [
  {
    "title": "Full-stack Developer",
    "company": "Naxca Limited",
    "location": "Manchester, England, UK",
    "duration": "Dec 2022 - Nov 2023",
    "content": [
      "Built an IoT platform in React.js and Express.js (TypeScript) to reduce domestic heating consumption by 13%.",
      "Processed 500+ telemetry data per second from sensors via AWS IoT to the rule engine for data aggregation and status monitoring; established WebSocket connection for real-time data visualization.",
      "Assisted the company in obtaining a governmental grant of £20k+ and securing the first prize with £15k in the 2023 Venture Future Awards curated by the University of Manchester."
    ],
    skills: ["React.js", "Express.js", "TypeScript", "Redux", "PostgreSQL", "Microservices", "Docker", "Kafka", "DigitalOcean", "AWS IoT", "ThingsBoard", "WebSocket", "Chart.js", "D3.js"],
  },
  {
    "title": "Software Engineer (Research Assistant)",
    "company": "Department of Computing, Xi'an Jiaotong-Liverpool University",
    "location": "Suzhou, Jiangsu, China",
    "duration": "Sept 2020 - Aug 2021",
    "content": [
      "Led the end-to-end development of the Online XJTLU Campus Map using Spring Boot, Vue.js, and Android.",
      "Organized 30+ students to collect university geographical data and saved 72% of the time for collection.",
      "Maintained constant contact with administrative departments for related resources; managed regular maintenance of the web server for project version updates and security supported by the IT department."
    ],
    "skills": ["Spring Boot", "Spring Cloud", "Vue.js", "Android", "Linux", "NginX", "PostgreSQL", "ElasticSearch", "Adobe Illustrator"]
  },
  {
    "title": "Software Engineer Intern",
    "company": "North China Oilfield Communication Company",
    "location": "Cangzhou, Hebei, China",
    "duration": "Jul 2020 - Aug 2020",
    "content": [
      "Initiated an online ordering system within 1 month to process 1000+ daily food orders and reduced manual collection time by 65%; generated in-depth daily ordering worksheets and reports for statistical analysis.",
      "Implemented the client-side WeChat Miniprogram and web-based management system based on Vue.js.",
      "Held several meetings with clients and reached agreements about system features; demonstrated teamwork skills with colleagues in the development team; mentored co-workers who participated later."
    ],
    "skills": ["Vue.js", "Wechat Miniprogram", "Bootstrap", "ECharts.js", "Excel.js"]
  },
  {
    "title": "Full-stack Developer",
    "company": "Suzhou Fuye Limited",
    "location": "Suzhou, Jiangsu, China",
    "duration": "Mar 2019 - Aug 2019",
    "content": [
      "Coordinated a campus forum for students in Wechat Miniprogram using Vue.js and Express.js; the application reached 2000+ concurrent users and obtained registrations of 68% of the university students within 2 months.",
      "Rolled out features under the Scrum framework in the team following CI/CD workflows."
    ],
    "skills": ["Vue.js", "React.js", "Wechat Miniprogram", "uni-app", "TypeScript", "MongoDB", "GraphQL", "Scrum"]
  },
  {
    "title": "Research Assistant",
    "company": "Summer Undergraduate Research Fellowship, Xi'an Jiaotong-Liverpool University",
    "location": "Suzhou, Jiangsu, China",
    "duration": "Jun 2018 - Aug 2018",
    "content": [
      "Built an automated intelligent unmanned boat for water quality monitoring; employed PID controller tuning algorithm for cruise control in the Arduino board using C++, increasing the efficiency of area coverage by 11%.",
      "Collected wireless real-time data and built a frontend web page for monitoring based on Spring MVC.",
      "Presented the project to supervisors and participants on poster day and was voted as one of the top 10 teams."
    ],
    "skills": ["C++", "Arduino", "ESP8266", "Spring MVC", "JSP", "MySQL", "Tomcat", "WebSocket", "PID Controller", "Android"]
  }
]

const ExperienceSection = ({ sectionName }) => {
  return (
    <div data-aos="fade-up">
      <div className="section-title">
        <h2>{sectionName}</h2>
      </div>

      <div className="timeline">
        {
          experienceArr.map(({ title, company, location, duration, content, skills }, i) => (
            <div key={i} className="timeline-item">
              <h4>{title}</h4>
              <h5>{duration}</h5>
              <p><em>{company} | {location}</em></p>
              <ul className="normal-list">
                { content.map((e2, j) => <li key={j}>{e2}</li>) }
              </ul>
              {
                skills &&
                <div className="skills-wrapper">
                  { skills.map((e, j) => <span key={j} className="mb-2 skill-text">{e}</span>) }
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default ExperienceSection
