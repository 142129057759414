import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import fallbackImg from '../assets/img/project-default.jpg';
import xapImg from '../assets/img/xap-img.png';
import zhitiaoImg from '../assets/img/zhitiao-img.jpg';
import pollutionImg from '../assets/img/pollution-img.png';


const ImageWithFallback = ({ src, alt, ...props }) => {
  const [currentSrc, setCurrentSrc] = useState(src);
  const [isErrorHandled, setIsErrorHandled] = useState(false);

  const handleError = () => {
    if (!isErrorHandled && currentSrc !== fallbackImg) {
      setCurrentSrc(fallbackImg);
      setIsErrorHandled(true);
    }
  }

  return <img src={currentSrc} alt={alt} onError={handleError} {...props} />
}

const projectArr = [
  {
    name: "XAP (Online XJTLU Campus Map)",
    description: "An online map application specifically desiged for Xi'an Jiaotong-Liverpool University. The application delivered commercial-level features which provide outdoor and indoor place information and route planning.",
    skills: ["Spring Boot", "Spring Cloud", "Vue.js", "Android", "PostgreSQL", "PostGIS", "ElasticSearch", "Redis", "JUnit", "Alibaba Cloud", "Microservices", "Path Planning", "A* Algorithm"],
    imgSrc: xapImg,
    imgAlt: "XAP Image",
    githubLink: "https://github.com/Anchanted/DXAPFrontend"
  },
  {
    name: "Zhitiao (Online University Student Forum)",
    description: "An online form for university communities where users can share their daily lives, new and ideas in the university anonymously.",
    skills: ["Express.js", "Vue.js", "Wechat Miniprogram", "uni-app", "MongoDB", "GraphQL"],
    imgSrc: zhitiaoImg,
    imgAlt: "Zhitiao Image",
    prodLink: "https://apps.apple.com/us/app/%E6%A0%A1%E5%9B%AD%E7%BA%B8%E6%9D%A1/id1596716760",
    prodType: "Product"
  },
  {
    name: "China's Air Quality Visualization Map",
    description: "A simple webpage displays data of different types of air pollution in China from 2013 to 2015 by days.",
    skills: ["Vue.js", "D3.js", "Three.js", "GitHub Actions", "AWS S3", "Terraform"],
    imgSrc: pollutionImg,
    imgAlt: "Air Quality Image",
    prodLink: "https://pollution.hanwenqi.dev/",
    prodType: "Live",
    githubLink: "https://github.com/Anchanted/pollution-visualization"
  },
  {
    name: "The Record Collection of Mimi... So Far",
    description: "A webpage about my record collection of Mariah Carey for a course in Year 2.",
    skills: ["React.js", "JQuery", "Canvas", "GitHub Actions", "AWS S3", "Terraform"],
    // imgSrc: pollutionImg,
    imgAlt: "Record Collection Image"
  }
]

const Project = ({ name, description, skills, imgSrc, imgAlt, prodLink, prodType, githubLink }) => {
  return (
    <div className="row gap-3 mb-5 mb-md-4 icon-box">
      <div className="col-sm-3 px-0">
        <ImageWithFallback className="" src={imgSrc || ""} alt={imgAlt || ""} width="1280" height="720" />
      </div>
      <div className="col-sm px-0 icon-box-info">
        <h4>{name}</h4>
        <p className="mt-2">{description}</p>
        <div className="mt-2 link-wrapper">
          { prodLink && <a href={prodLink} target="_blank" rel="noopener noreferrer" className="mt-2 me-3 external-link" title={`View ${prodType || "Demo"}`}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a> }
          { githubLink && <a href={githubLink} target="_blank" rel="noopener noreferrer" className="mt-2 me-3 github" title="View Github Repository"><FontAwesomeIcon icon={faGithub} /></a> }
        </div>
        {
          skills &&
          <div className="mt-2 skill-wrapper">
            { skills.map((e, i) => <span key={i} className="mt-2 skill-text">{e}</span>) }
          </div>
        }
      </div>
    </div>
  )
}

const ProjectsSection = ({ sectionName }) => {
  return (
    <div data-aos="fade-up">
      <div className="section-title">
        <h2>{sectionName}</h2>
      </div>

      <div className="projects-container" data-aos="fade-up" data-aos-delay="200">

        <div className="aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
          {
            projectArr.map((e, i) =>
              <Project 
                key={i}
                {... e}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default ProjectsSection
