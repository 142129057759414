import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css'
import Typed from './typed/typed';
import { Tooltip } from 'bootstrap';
import { scrollto, select } from './utils';
import SkillsSection from './components/SkillsSection';
import EducationSection from './components/EducationSection';
import ExperienceSection from './components/ExperienceSection';
import ProjectsSection from './components/ProjectsSection';


const navInfoArr = [
  { name: "About", },
  { name: "Skills", },
  { name: "Education", },
  { name: "Experience", },
  { name: "Projects", },
  // { name: "Contact", }
]

function App() {
  const [isLoaded, setIsLoaded] = useState(false)
  // const [isMenuDisplayed, setIsMenuDisplayed] = useState(false)
  const [isBackToTopActive, setIsBackToTopActive] = useState(false)
  const [currentNavIdx, setCurrentNavIdx] = useState(-1)
  const [isRtlStyleActive, setIsRtlStyleActive] = useState(false)
  
  const navSectionRefArr = useRef(navInfoArr.map(_ => React.createRef()))
  const typedEl = useRef(null);

  useEffect(() => {
    // Preloader
    setIsLoaded(true)

    // Scroll with offset on page load with hash links in the url
    if (window.location.hash && select(window.location.hash)) {
      // TODO: Not the right position
      // console.log(Array.from(document.querySelectorAll('section')).map(el => window.getComputedStyle(el)['height']))
      // setTimeout(() => {
      //   console.log(Array.from(document.querySelectorAll('section')).map(el => window.getComputedStyle(el)['height']))
      // }, 1000)
      scrollto(window.location.hash, true)
    }

    // Typed.js
    const typed = new Typed(typedEl.current, {
      strings: [
        'I\'m a computer science engineer,', 
        'wo|2我shi|3是yi|2一ming|4名ji|2计suan|4算ji|2机ke|2科xue|3学gong|4工cheng|5程shi|3师，',
        'Soy un ingeniero de informática,',
        'أنا مهندس علم الحاسوب،'
      ],
      loop: true,
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 2000,
      preStringTyped: (arrayPos, self) => {
        setIsRtlStyleActive(arrayPos === self.strings.length - 1)
      }
    });

    // Animation on scroll
    Aos.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    })

    // ToolTip for skill icons
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

    //Back to top button
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        setIsBackToTopActive(true)
      } else {
        setIsBackToTopActive(false)
      }
    }

    // Navbar links active state on scroll
    const navbarlinksActive = () => {
      let position = window.scrollY + 200
      const currentIdx = navSectionRefArr.current.findIndex(ref => {
        const section = ref.current
        return position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)
      })
      // if (currentIdx !== currentNavIdx) {
        setCurrentNavIdx(currentIdx)
      // }
    }

    const onScrollEvent = (e) => {
      toggleBacktotop()
      navbarlinksActive()
    }
    
    onScrollEvent()

    document.addEventListener('scroll', onScrollEvent)

    return () => {
      tooltipList.map(t => t.dispose())
      typed.destroy()
      document.removeEventListener('scroll', onScrollEvent)
    }
  }, [])

  const handleOnClickBackToTopBtn = () => {
    scrollto()
    // window.location.hash = ""
  }

  const handleOnClickNavItem = (e, i) => {
    e.preventDefault();
    // let body = select('body')
    // if (body.classList.contains('mobile-nav-active')) {
    //   body.classList.remove('mobile-nav-active')
    //   setIsMenuDisplayed(false);
    // }
    const id = `#${navInfoArr[i].name.toLowerCase()}`
    scrollto(id, true)
    // window.location.hash = id
    window.history.replaceState(null, null, id);
  }

  return (
    <div className="App mx-auto px-4 py-5 px-lg-5 py-lg-0">
      { !isLoaded && <div id="preloader"></div> }

      <div className="d-lg-flex justify-content-lg-between">
        {/* ======= Header ======= */}
        <header id="header" className="py-lg-5 d-lg-flex flex-lg-column justify-content-lg-between">

          <div className="header-info" data-aos="fade-right">
            <h1 className="header-info-name">Wenqi Han</h1>
            <div className={`header-info-intro mt-4 typed-wrapper ${isRtlStyleActive ? 'typed-rtl' : ''}`}><span className="typed" ref={typedEl}></span></div>
            <div className="header-info-intro">specializing in Software Engineering and Machine Learning.</div>
            
            <nav id="navbar" className="navbar nav-menu d-none d-lg-block">
              <ul className='mt-5'>
                {
                  navInfoArr.map(({ name }, i) => (
                    <li key={i}>
                      <a href={`#${name.toLowerCase()}`} className={`nav-link scrollto ${i === currentNavIdx ? 'active' : ''}`} onClick={e => handleOnClickNavItem(e, i)}>
                        <span className="nav-text">{name}</span>
                      </a>
                    </li>
                  ))
                }
              </ul>
            </nav>
            {/* .nav-menu */}
          </div>


          <div className="social-links" data-aos="fade-right" data-aos-anchor="#header">
            <a href="https://www.linkedin.com/in/hanwenqi/" target="_blank" rel="noopener noreferrer" className="linkedin" title="View LinkedIn"><FontAwesomeIcon icon={faLinkedinIn} /></a>
            <a href="https://github.com/Anchanted" target="_blank" rel="noopener noreferrer" className="github" title="View Github"><FontAwesomeIcon icon={faGithub} /></a>
            <a href="mailto:wenqiwaltonhan@gmail.com" target="_blank" rel="noopener noreferrer" className="email" title="Email Me"><FontAwesomeIcon icon={faEnvelope} /></a>
          </div>

        </header>{/* End Header */}

        <main id="main" className="pt-5 py-lg-5">

          {/* ======= About Section ======= */}
          <section id="about" className="about" ref={navSectionRefArr.current[0]}>
            <div data-aos="fade-up">

              <div className="section-title">
                <h2>{navInfoArr[0].name}</h2>
              </div>

              <div className="about-content">
                <p>
                  Hi, I'm Wenqi. I started my software engineering journey during my undergraduate studies and have gained full-stack software development experience with agile practices. 
                  I also have a massive passion for Artificial Intelligence, focusing on Natural Language Processing and Computer Vision. 
                </p>
                <p>
                  My curiosity is a driving force in my professional life, always pushing me to learn new techniques and adapt to new environments. 
                  I thrive in cross-functional teams, finding joy in the collaborative process. 
                  I'm particularly excited about the opportunity to develop new products that can make a real impact and bring benefits to other people.
                </p>
                <p>
                  I like reading, movies, and music. I enjoy cooking dishes from different parts of the world. I love learning languages, through which I believe I can reach the soul of their native speakers.
                </p>
              </div>

            </div>
          </section>
          {/* End About Section */}

          {/* ======= Skills Section ======= */}
          <section id="skills" className="skills section-bg" ref={navSectionRefArr.current[1]}>
            <SkillsSection sectionName={navInfoArr[1].name} />
          </section>
          {/* End Skills Section */}

          {/* ======= Education Section ======= */}
          <section id="education" className="education" ref={navSectionRefArr.current[2]}>
            <EducationSection sectionName={navInfoArr[2].name} />
          </section>
          {/* End Education Section */}

          {/* ======= Experience Section ======= */}
          <section id="experience" className="experience" ref={navSectionRefArr.current[3]}>
            <ExperienceSection sectionName={navInfoArr[3].name} />
          </section>
          {/* End Experience Section */}

          {/* ======= Projects Section ======= */}
          <section id="projects" className="projects section-bg" ref={navSectionRefArr.current[4]}>
            <ProjectsSection sectionName={navInfoArr[4].name} />
          </section>
          {/* End Projects Section */}

          {/* ======= Contact Section ======= */}
          {/* <section id="contact" className="contact" ref={navSectionRefArr.current[5]}>
            <div data-aos="fade-up">

              <div className="section-title">
                <h2>Contact</h2>
                <p>Looking forward to hearing from you</p>
              </div>

              <div className="row mt-1">

                <div className="col-lg-4">
                  <div className="info">
                    <div className="address">
                      <h4>Location:</h4>
                      <p>A108 Adam Street, New York, NY 535022</p>
                    </div>

                    <div className="email">
                      <h4>Email:</h4>
                      <p>info@example.com</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 mt-4 mt-lg-0">

                  <form action="forms/contact.php" method="post" role="form" className="contact-email-form">
                    <div className="row form-group-row">
                      <div className="col-md form-group px-0">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                      </div>
                      <div className="col-md form-group mt-3 mt-md-0 px-0">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
                    </div>
                    <div className="form-group mt-3">
                      <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button type="submit">Send Message</button></div>
                  </form>

                </div>

              </div>

            </div>
          </section> */}
          {/* End Contact Section */}

        </main>
        {/* End #main */}

        <button className={`back-to-top d-flex align-items-center justify-content-center ${isBackToTopActive ? 'active' : ''}`} onClick={handleOnClickBackToTopBtn}><FontAwesomeIcon icon={faArrowUp} /></button>
      
      </div>
    </div>
  );
}

export default App;
