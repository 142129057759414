import { Icon } from '@iconify/react';

const skillGroupArr = [
  {
    "title": "Full-stack Development",
    "skills": [
      { "iconName": "devicon:spring", "name": "Spring" },
      { "iconName": "devicon:nodejs-wordmark", "name": "Node.js" },
      { "iconName": "devicon:react", "name": "React.js" },
      { "iconName": "devicon:vuejs", "name": "Vue.js" },
      { "iconName": "devicon:typescript", "name": "TypeScript" },
      { "iconName": "devicon:sass", "name": "SCSS" },
      { "iconName": "devicon:android", "name": "Android" },
      { "iconName": "devicon:mysql", "name": "MySQL" },
      { "iconName": "devicon:postgresql", "name": "PostgreSQL" },
      { "iconName": "devicon:mongodb", "name": "MongoDB" },
      { "iconName": "devicon:redis", "name": "Redis" },
      { "iconName": "devicon:elasticsearch", "name": "Elasticsearch" },
      { "iconName": "logos:nginx", "name": "Nginx" },
      { "iconName": "logos:graphql", "name": "GraphQL" },
      { "iconName": "devicon:npm-wordmark", "name": "npm" },
      { "iconName": "devicon:maven", "name": "Maven" },
      { "iconName": "devicon:swagger", "name": "Swagger" }
    ],
    "descriptions": [
      <>Developing end-to-end full-stack applications using frameworks of <b>Java</b> and <b>JavaScript (TypeScript)</b> following <b>microservices</b> architecture, and experience for native <b>Android</b> applications.</>,
      <>Participation in the full software development lifecycle (<b>SDLC</b>) by adopting <b>Scrum</b> for <b>Agile Development</b> and test driven development (<b>TDD</b>).</>
    ]
  },
  {
    "title": "Cloud-native Infrastructure",
    "skills": [
      { "iconName": "devicon:git", "name": "Git" },
      { "iconName": "devicon:linux", "name": "Linux" },
      { "iconName": "logos:aws", "name": "AWS" },
      { "iconName": "devicon:digitalocean", "name": "DigitalOcean" },
      { "iconName": "simple-icons:alibabacloud", "name": "Alibaba Cloud", style: { color: "#FF6A00" } },
      { "iconName": "devicon:docker", "name": "Docker" },
      { "iconName": "devicon:kubernetes", "name": "Kubernetes" },
      { "iconName": "devicon:apachekafka", "name": "Kafka" },
      { "iconName": "devicon:rabbitmq", "name": "RabbitMQ" },
      { "iconName": "devicon:githubactions", "name": "GitHub Actions" },
      { "iconName": "devicon:terraform", "name": "Terraform" },
      { "iconName": "devicon:azuredevops", "name": "Azure DevOps" },
      { "iconName": "devicon:jenkins", "name": "Jenkins" },
      { "iconName": "devicon:ansible", "name": "Ansible" }
    ],
    "descriptions": [
      <>Utilizing servers based on cloud platforms such as AWS, GCP, Azure, and Alibaba Cloud.</>,
      <>Deploying containerized applications using <b>Docker</b> and <b>Kubernetes</b> to deliver highly scalable, secure, and fault-tolerant microservices products.</>,
      <>Adopting Infrastructure as Code (<b>IaC</b>) to provision infrastructures for different environments and <b>DevOps</b> tools to automate <b>CI/CD</b> workflow.</>
    ]
  },
  {
    "title": "Machine Learning",
    "skills": [
      { "iconName": "logos:tensorflow", "name": "TensorFlow" },
      { "iconName": "devicon:keras", "name": "Keras" },
      { "iconName": "devicon:pytorch", "name": "PyTorch" },
      { "iconName": "devicon:scikitlearn", "name": "scikit-learn" },
      { "iconName": "logos:jupyter", "name": "Jupyter" },
    ],
    "descriptions": [
      <>Adopting Machine Learning algorithms from supervised learning and unsupervised learning for statistical use cases.</>,
      <>Training Deep Neural Network (<b>DNN</b>) models for Natural Language Processing (<b>NLP</b>) and <b>Computer Vision</b>.</>
    ]
  }
]

const SkillsSection = ({ sectionName }) => {
  return (
    <div data-aos="fade-up">

      <div className="section-title">
        <h2>{sectionName}</h2>
      </div>

      <div className="skills-content">
        {
          skillGroupArr.map(({ title, skills, descriptions }, i) => (
            <div key={i} className="mb-5 skills-subsection">
              <h3 className="section-subtitle">{title}</h3>
              <div className="skills-icon-bar d-flex flex-wrap justify-content-center">
                {
                  skills.map(({ iconName, name, style }, j) => (
                    <div key={`${i},${j}`} data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={name}>
                      <Icon title="title" className="skills-icon" icon={iconName} {...(style && { ...style })} preserveAspectRatio="xMidYMid meet" />
                    </div>
                  ))
                }
              </div>
              <ul className="normal-list">
                {
                  descriptions.map((e, i) => <li key={i}>{e}</li>)
                }
              </ul>
            </div>
          ))
        }
      </div>

    </div>
  )
};

export default SkillsSection;
