/**
 * Easy selector helper function
 */
const select = (el, all = false) => {
  el = el.trim()
  if (all) {
    return [...document.querySelectorAll(el)]
  } else {
    return document.querySelector(el)
  }
}

/**
 * Scrolls to an element with header offset
 */
const scrollto = (elStr, addParentOffset = false) => {
  let elementPos = 0
  if (elStr) {
    const el = select(elStr)
    if (el) {
      elementPos = el.offsetTop
      if (addParentOffset) {
        elementPos -= parseInt(window.getComputedStyle(el.parentElement)['padding-top'])
      }
    }
  }
  window.scrollTo({
    top: elementPos,
    behavior: 'smooth'
  })
}

export {
  select,
  scrollto
}
