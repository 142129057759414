const EducationSection = ({ sectionName }) => {
  return (
    <>
      <div data-aos="fade-up">
        <div className="section-title">
          <h2>{sectionName}</h2>
        </div>

        <div className="timeline">
          <div className="timeline-item">
            <h4>The University of Manchester</h4>
            <h5>2021 - 2022</h5>
            <p><em>MSc in Advanced Computer Science (Merit, 2:1)</em></p>
            <p>I chose Artificial Intelligence as the pathway of the program. I took courses related to Web Data Engineering, and AI courses such as Representation Learning, Text Mining, and Computer Vision</p>
          </div>
          <div className="timeline-item">
            <h4>Xi'an Jiaotong-Liverpool University</h4>
            <h5>2016 - 2020</h5>
            <p><em>BSc (Hons) in Information and Computing Science (First-class)</em></p>
            <p>I took a range of courses related to the majority of the fields in Computer Science, such as Database, Networking, Software Engineering, Machine Learning, Big Data, and Computer Security. I participated in Summer Undergraduate Research Fellowship program from the university.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default EducationSection
